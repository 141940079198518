// Hero.tsx
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className="relative min-h-screen bg-black overflow-hidden">
      {/* Art Deco Pattern Overlay */}
      <div className="absolute inset-0 opacity-10">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage:
              "url('https://placehold.co/1920x1080/2C2C2C/2C2C2C?text=')",
          }}
        ></div>
      </div>

      {/* Main Content */}
      <div className="relative z-10 container mx-auto px-4 h-screen flex items-center">
        <div className="w-full flex desktop:flex-row laptop:flex-row tablet:flex-col mobile:flex-col items-center justify-between">
          {/* Text Content */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full desktop:pr-8 laptop:pr-8 tablet:pr-0 mobile:pr-0"
          >
            <h1 className="desktop:text-7xl laptop:text-6xl tablet:text-5xl mobile:text-4xl font-serif text-gold mb-6">
              Elevate Your Style
            </h1>
            <p className="desktop:text-xl laptop:text-xl tablet:text-lg mobile:text-base text-gray-300 mb-8">
              Experience the pinnacle of luxury hair artistry in our art
              deco-inspired salon
            </p>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <Link
                to="/book"
                className="inline-block px-8 py-4 bg-gold text-black font-semibold border-2 border-gold hover:bg-transparent hover:text-gold transition-all duration-300"
              >
                Book Appointment
              </Link>
            </motion.div>
          </motion.div>

          {/* Image */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="desktop:w-1/2 laptop:w-1/2 tablet:w-full mobile:w-full desktop:mt-0 laptop:mt-0 tablet:mt-8 mobile:mt-8"
          >
            <div className="relative">
              <div className="absolute inset-0 bg-gold opacity-20 transform rotate-3"></div>
              <img
                src="https://placehold.co/600x800/2C2C2C/CCCCCC?text=Elegant+Hairstyle"
                alt="Luxury Hairstyling"
                className="relative z-10 w-full h-auto object-cover"
              />
            </div>
          </motion.div>
        </div>
      </div>

      {/* Decorative Elements */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
        className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-black to-transparent"
      />
    </div>
  );
};

export default Hero;

// Add custom styles
const style = document.createElement("style");
style.textContent = `
  .text-gold {
    color: #D4AF37;
  }
  
  .bg-gold {
    background-color: #D4AF37;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
document.head.appendChild(style);
